@-webkit-keyframes spinAround {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

@keyframes spinAround {
    from {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }
    to {
        -webkit-transform: rotate(359deg);
        transform: rotate(359deg);
    }
}

[data-action="iconPicker"] {
    width: 0;
    height: 0;
    display: block;
    opacity: 0;
    top: -999px;
    position: absolute;
}

.iconpicker-preview {
    border-radius: 100%;
    background-color: #f5f5f5;
}

.iconpicker-preview:hover {
    cursor: pointer;
}

.iconpicker-preview .iconpicker-icon-preview {
    color: #7a7a7a;
    font-size: 1.5em;
}

.iconpicker-modal .modal-card .modal-card-head {
    background: #00d1b2;
    color: #fff;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 1rem;
}

.iconpicker-modal .modal-card .modal-card-head .modal-card-title {
    font-size: 1.2em;
    color: #fff;
}

.iconpicker-modal .modal-card .modal-card-head .iconpicker-search {
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 2em;
    box-shadow: none;
    border: 0.1em solid rgba(255, 255, 255, 0.3);
    margin: 0 2em;
    padding: 0.3em 1em;
    color: #7a7a7a;
}

.iconpicker-modal .iconpicker-sets {
    flex-shrink: 0;
    background-color: #f5f5f5;
    font-size: 0.8em;
    margin: 0;
    margin-bottom: 0!important;
}

.iconpicker-modal .modal-card-body {
    padding: 0;
}

.iconpicker-modal .modal-card-body .iconpicker-icons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1em;
}

.iconpicker-modal .modal-card-body .iconpicker-icons [data-icon] {
    display: block;
    width: 48px;
    height: 48px;
    text-align: center;
    padding: 0.5rem;
}

.iconpicker-modal .modal-card-body .iconpicker-icons [data-icon] .iconpicker-icon-preview {
    font-size: 1.4em;
}